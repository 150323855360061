$(function () {
  function getAjax(URL, funct) {
    var $tireFilters = $('#tire-filters');
    try {
      $.ajax({
        url: URL + '&funct=' + funct,
        success: function (data) {
          if (funct === 'width') {
            $('#aspect').html(data);
            $('#aspect').removeAttr('disabled');
          } else if (funct === 'aspect') {
            $('#diam').html(data);
            $('#diam').removeAttr('disabled');
          } else {
            $('#tireResults').html(data);
          }
        }
      });
    } catch (err) {
      window.location.reload();
    }
  }

  function buildURL(method) {
    var $tireFilters = $('#tire-filters');
    var width = $tireFilters.find('#width').val();
    var aspect = $tireFilters.find('#aspect').val();
    var diam = $tireFilters.find('#diam').val();
    var url = '/ajax/connector.cfc?method=getTireSizes';
    if (method = 'width') {
      url += '&width=' + width;
    }
    return url;

  }
  $('#tire-filters #width').change(function () {
    var width = $(this).val();
    if (width !== '') {
      $('#diam').attr('disabled', 'disabled');
      var url = buildURL(width);
      //var url = 'getTireSizes.cfm?width=' + width + '&method=getTireSizes';
      getAjax(url, 'width');
    }
  });

  $('#tire-filters #aspect').change(function () {
    var width = $('#tire-filters').find('#width').val();
    var aspect = $(this).val();

    if (aspect !== '') {
      var url = buildURL();
      var url = '/ajax/connector.cfc?aspect=' + aspect + '&width=' + width + '&method=getTireSizes';
      getAjax(url, 'aspect');
    }
  });

  $('#tire-filters #diam').change(function () {
    var diam = $(this).val();
    if (diam !== '') {

      $.magnificPopup.open({
        items: {
          src: '<div class="white text-center processing">' +
            '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
            '</div>',
        },
        closeBtnInside: false,
      });

      var $tireFilters = $('#tire-filters');
      var width = $tireFilters.find('#width').val();
      var aspect = $tireFilters.find('#aspect').val();
      var url = '/tires/?aspect=' + aspect + '&width=' + width + '&diam=' + diam;
      window.location.href = url;
    }
  });

})