// Add to cart button
$('.buy').click(function(e){
    let strPartnumber = $(this).attr('data-part');
    let strSpecID = $(this).attr('data-spec-id');
    let strItemID = $(this).attr('data-id');
    let strTypeID = $(this).attr('data-type-id');
    let strQty = $(this).attr('data-qty');
    let strSpec =  $(this).attr('data-desc');
    var dataString = 'baseid=' + strItemID+'&type=' + strTypeID+'&part=' + encodeURIComponent(strPartnumber) +'&qty='+ strQty + '&spec='+ encodeURIComponent(strSpec);
    $.ajax({
        type: "POST",
        url: "/ajax/addtocart.cfm",
        data: dataString,
        success: function(data) {
            if($.trim(data)  =='expired'){
                window.location.reload();
            }else{
                $.magnificPopup.open({
                    items: {
                        src: '#accessory-popup',
                        type: 'inline',
                    },
                });
            }
        }
    });
});


$('.addAccessory').on('click', function() {
    const set = $('.accessory-container input[type=checkbox]:checked');
    var length = set.length;

    $('.accessory-container input[type=checkbox]:checked').each(function(index) {
        if(this.checked) {
            let accessory = $(this).data();
            let strPartnumber = accessory.partnumber;
            let strItemID = accessory.id;
            let strTypeID = accessory.typeId;
            let strQty = 1;
            let strSpec =  accessory.desc;
            var dataString = 'baseid=' + strItemID+'&type=' + strTypeID+'&part=' + encodeURIComponent(strPartnumber) +'&qty='+ strQty + '&spec='+ encodeURIComponent(strSpec);
            $.ajax({
                type: "POST",
                url: "/ajax/addtocart.cfm",
                data: dataString,
                success: function(data) {
                    if($.trim(data)  =='expired'){
                        window.location.reload();
                    }
                    if (index === (length - 1)) {
                        window.location = '/cart.cfm';
                    }
                }
            });
        }
    })
});

$('.addWheel').on('click', function() {
    const set = $('.item-wheel');
    var length = set.length;

    $('.item-wheel').each(function(index) {
        let strPartnumber = $(this).attr('data-part');
        let strItemID = $(this).attr('data-id');
        let strTypeID = $(this).attr('data-type-id');
        let strQty = $(this).attr('data-qty');
        let strSpec =  $(this).attr('data-desc');
        var dataString = 'baseid=' + strItemID+'&type=' + strTypeID+'&part=' + encodeURIComponent(strPartnumber) +'&qty='+ strQty + '&spec='+ encodeURIComponent(strSpec);
        $.ajax({
            type: "POST",
            url: "/ajax/addtocart.cfm",
            data: dataString,
            success: function(data) {
                if($.trim(data)  =='expired'){
                    window.location.reload();
                }
            }
        });
    })
});



$(function() {
    $("#submit").click(function() {
        var fail = false;
        $(".required").each(function() {
            if($(this).val() == "")  {
                fail = true;
                $(this).css("background-color", "red");
            } else {
                $(this).css("background-color", "");
            }


        });


        if(fail) {
            alert("Please fill out all fields in red.");
            return false;
        } else {
            console.log($("#agree").is(':checked'));
            if(!$("#agree").is(':checked'))  {
                alert("Please Agree to the the terms of service.");
                $("#adiv").css("background-color", "red");
                return false;
            } else {
               $("#form").submit();
            }

        }
    });


$('#sab').click(function(e){
    if( $("#sab").is(':checked') )  {
        $('#shipfirst').val($('#billfirst').val());
        $('#shiplast').val($('#billlast').val());
        $('#shipadd').val($('#billadd').val());
        $('#shipcity').val($('#billcity').val());
        $('#shipcountry').val($('#billcountry').val());
        $('#shipstate').val($('#billstate').val());
        $('#shipzip').val($('#billzip').val());
        $('#shipphone').val($('#billphone').val());
        $('#shipemail').val($('#billemail').val());

    }


});
  $('#financing').change(function(){
    if ($('#financing option:selected').val() == 'yes'){
        $('#refno').show();
    } else {
        $('#refno').hide();
    }
});

});